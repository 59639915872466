import React, { useState } from 'react';
import { BarChart, Target, PieChart, MessageCircle, LineChart, Mail } from 'lucide-react';

const PlexiProPage = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    company: '',
    employees: '',
    feedback: ''
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form submitted:', formData);
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white">
      {/* Hero Section */}
      <header className="py-24 px-4">
        <div className="max-w-6xl mx-auto text-center">
          <h1 className="text-4xl font-bold text-blue-900 mb-6">
            Transform Your Business Insights with PlexiPro
          </h1>
          <p className="text-xl text-gray-700 mb-8 max-w-3xl mx-auto">
            Unlock valuable customer feedback and boost engagement with our advanced survey solutions. 
            We help businesses make data-driven decisions through powerful analytics.
          </p>
          <div className="flex justify-center gap-4">
            <button className="bg-blue-600 text-white px-8 py-3 rounded-md text-lg hover:bg-blue-700 transition-colors">
              Start Free Trial
            </button>
            <button className="border border-blue-600 text-blue-600 px-8 py-3 rounded-md text-lg hover:bg-blue-50 transition-colors">
              Watch Demo
            </button>
          </div>
        </div>
      </header>

      {/* Stats Section */}
      <section className="py-12 px-4 bg-white">
        <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-4 gap-8">
          {[
            { label: 'Surveys Completed', value: '1M+' },
            { label: 'Business Clients', value: '10K+' },
            { label: 'Response Rate', value: '85%' },
            { label: 'Data Points Analyzed', value: '500M+' }
          ].map((stat, i) => (
            <div key={i} className="p-6">
              <h3 className="text-3xl font-bold text-blue-600 mb-2">{stat.value}</h3>
              <p className="text-gray-600">{stat.label}</p>
            </div>
          ))}
        </div>
      </section>

      {/* Features Section */}
      <section className="py-16 px-4">
        <h2 className="text-3xl font-bold text-center mb-12">Powerful Features for Better Insights</h2>
        <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-8">
          {[
            {
              icon: <BarChart />,
              title: "Advanced Analytics",
              description: "Transform raw data into actionable insights with our powerful analytics engine."
            },
            {
              icon: <Target />,
              title: "Targeted Surveys",
              description: "Reach the right audience with smart segmentation and targeting options."
            },
            {
              icon: <PieChart />,
              title: "Visual Reports",
              description: "Beautiful, interactive dashboards that make data interpretation easy."
            },
            {
              icon: <MessageCircle />,
              title: "Real-time Feedback",
              description: "Collect and analyze customer feedback in real-time for faster decision making."
            },
            {
              icon: <LineChart />,
              title: "Trend Analysis",
              description: "Identify patterns and trends in your survey data over time."
            },
            {
              icon: <Mail />,
              title: "Automated Campaigns",
              description: "Set up and manage survey campaigns with powerful automation tools."
            }
          ].map((feature, i) => (
            <div key={i} className="bg-white p-6 rounded-lg shadow-lg transform hover:scale-105 transition-transform">
              <div className="text-blue-600 mb-4">{feature.icon}</div>
              <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
              <p className="text-gray-600">{feature.description}</p>
            </div>
          ))}
        </div>
      </section>

      {/* How It Works Section */}
      <section className="py-16 px-4 bg-gray-50">
        <h2 className="text-3xl font-bold text-center mb-12">How It Works</h2>
        <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-4 gap-8">
          {[
            {
              step: "1",
              title: "Design Your Survey",
              description: "Choose from templates or create custom surveys tailored to your needs."
            },
            {
              step: "2",
              title: "Distribute",
              description: "Share surveys across multiple channels to reach your target audience."
            },
            {
              step: "3",
              title: "Collect & Analyze",
              description: "Watch responses roll in and get real-time analytics and insights."
            },
            {
              step: "4",
              title: "Take Action",
              description: "Make data-driven decisions based on comprehensive insights."
            }
          ].map((step, i) => (
            <div key={i} className="text-center">
              <div className="w-12 h-12 bg-blue-600 text-white rounded-full flex items-center justify-center text-xl font-bold mx-auto mb-4">
                {step.step}
              </div>
              <h3 className="text-xl font-semibold mb-2">{step.title}</h3>
              <p className="text-gray-600">{step.description}</p>
            </div>
          ))}
        </div>
      </section>

      {/* Contact Form Section */}
      <section className="py-16 px-4 bg-white">
        <div className="max-w-2xl mx-auto bg-white rounded-lg shadow-xl p-8">
          <h2 className="text-3xl font-bold text-center mb-12">Get Started with PlexiPro</h2>
          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
              <div className="mb-4">
                <label className="block text-sm font-medium mb-2">Name</label>
                <input
                  type="text"
                  value={formData.name}
                  onChange={(e) => setFormData({...formData, name: e.target.value})}
                  placeholder="Your name"
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-600 focus:ring-2 focus:ring-blue-600"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium mb-2">Email</label>
                <input
                  type="email"
                  value={formData.email}
                  onChange={(e) => setFormData({...formData, email: e.target.value})}
                  placeholder="your@email.com"
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-600 focus:ring-2 focus:ring-blue-600"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium mb-2">Company</label>
                <input
                  type="text"
                  value={formData.company}
                  onChange={(e) => setFormData({...formData, company: e.target.value})}
                  placeholder="Company name"
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-600 focus:ring-2 focus:ring-blue-600"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium mb-2">Company Size</label>
                <input
                  type="text"
                  value={formData.employees}
                  onChange={(e) => setFormData({...formData, employees: e.target.value})}
                  placeholder="Number of employees"
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-600 focus:ring-2 focus:ring-blue-600"
                  required
                />
              </div>
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium mb-2">How can we help?</label>
              <textarea
                value={formData.feedback}
                onChange={(e) => setFormData({...formData, feedback: e.target.value})}
                placeholder="Tell us about your survey needs..."
                className="w-full px-4 py-2 border border-gray-300 rounded-md h-32 focus:outline-none focus:border-blue-600 focus:ring-2 focus:ring-blue-600"
                required
              />
            </div>
            <button 
              type="submit" 
              className="w-full bg-blue-600 text-white py-3 rounded-md font-medium hover:bg-blue-700 transition-colors"
            >
              Schedule a Demo
            </button>
          </form>
        </div>
      </section>
    </div>
  );
};

export default PlexiProPage;